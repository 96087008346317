import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'application/stylesheets/index.scss'

import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'

import 'application/dns_record_form'

require("selectize")

$(document).on('turbolinks:load', function() { // runs every time a page is rendered
  $('.phone').each(function(index) {
    const $phone_input = $(this)
    const instance = intlTelInput($phone_input[0], { separateDialCode: true })
    const $phone_cc_input = $phone_input.closest('.intl-tel-input').siblings('.phone_cc')
    const current_number = `+${$phone_cc_input.val()}${$phone_input.val()}`
    instance.setNumber(current_number);
    $phone_input.on('countrychange', function() {
      $phone_cc_input.val(instance.getSelectedCountryData().dialCode)
    })
  })

  let contact_html = (contact, escape) => {
    return `<div>
      <div class="line">
        <span class="name">${escape(contact.name)}</span>,
        <span class="company">${escape(contact.company)}</span>
      </div>
      <div class="line">
        <span class="email">${escape(contact.email)}</span> -
        <span class="phone">${intlTelInputUtils.getExampleNumber(escape(contact.phone_cc))}</span>
        <span class="phone">${intlTelInputUtils.formatNumber(escape(contact.phone), escape(contact.country), intlTelInputUtils.numberFormat.NATIONAL)}</span>
      </div>
      <div class="line">
        <span class="address">
          ${escape(contact.address_line_1)},
          ${contact.address_line_2 ? escape(contact.address_line_2) + ',' : ''}
          ${escape(contact.city)},
          ${escape(contact.state)}
          ${escape(contact.zipcode)}
        </span>
      </div>
    </div>`
  }

  $(".contact-select").selectize({
    render: {
      item(item, escape) {
        return contact_html(item, escape)
      },
      option(item, escape) {
        return contact_html(item, escape)
      }
    }
  });
  $('.contact-select .selectize-dropdown').prepend(
    `<a class="manage-contacts" href="/contacts" target="_blank">
      Manage Existing Contacts
    </a>`
  );

})
