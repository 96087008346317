$(document).on('change', 'select[name="dns_record[record_type]"]', function() {
  let $form = $(this).closest('form')
  let $priority = $form.find('input[name="dns_record[priority]"]')
  let $port = $form.find('input[name="dns_record[port]"]')
  let $weight = $form.find('input[name="dns_record[weight]"]')
  if (this.value == 'MX') {
    console.log('priority', $priority, 'parent', $priority.parent())
    $priority.parent().show()
    $weight.parent().hide()
    $port.parent().hide()
    $weight.val('')
    $port.val('')
  } else if (this.value == 'SRV') {
    $priority.parent().show()
    $weight.parent().show()
    $port.parent().show()
  } else {
    $priority.parent().hide()
    $weight.parent().hide()
    $port.parent().hide()
    $priority.val('')
    $weight.val('')
    $port.val('')
  }
})

$(document).on('turbolinks:load', function() { // runs every time a page is rendered
  $('select[name="dns_record[record_type]"]').trigger('change')
})
